<template>
  <section class="section">
    <div class="section-header">
      <h1>Dashboard</h1>
    </div>
    <div class="row">
      <div class="col-lg-3 col-md-6 col-sm-6 col-12">
        <div class="card card-statistic-1">
          <div class="card-icon bg-primary">
            <i class="fas fa-phone"></i>
          </div>
          <div class="card-wrap">
            <div class="card-header">
              <h4>Incoming Calls</h4>
            </div>
            <div class="card-body">{{ `${incoming_calls.incoming_calls_stats_resolved}/${incoming_calls.incoming_calls_stats}` }}</div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6 col-12">
        <div class="card card-statistic-1">
          <div class="card-icon bg-danger">
            <i class="far fa-file-alt"></i>
          </div>
          <div class="card-wrap">
            <div class="card-header">
              <h4>Tickets</h4>
            </div>
            <div class="card-body">{{ `${tickets.tickets_stats_closed}/${tickets.tickets_stats}` }}</div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6 col-12">
        <div class="card card-statistic-1">
          <div class="card-icon bg-warning">
            <i class="fas fa-circle"></i>
          </div>
          <div class="card-wrap">
            <div class="card-header">
              <h4>Total Task</h4>
            </div>
            <div class="card-body">{{ tasks.tasks_stats }}</div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6 col-12">
        <div class="card card-statistic-1">
          <div class="card-icon bg-success">
            <i class="fas fa-circle"></i>
          </div>
          <div class="card-wrap">
            <div class="card-header">
              <h4>Completed Tasks Items</h4>
            </div>
            <div class="card-body">{{ `${tasks.tasks_stats_completed}/${tasks.tasks_stats}` }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-6 col-12 col-sm-12">
        <div class="card">
          <div class="card-header">
            <h4>Recent Tickets</h4>
          </div>
          <div class="card-body">
            <ul class="list-unstyled list-unstyled-border" v-if="tickets && tickets.tickets && tickets.tickets.length > 0">
              <li class="media" v-for="(ticket, index) in tickets && tickets.tickets.slice(0, 3)" :key="index">
                <img
                  class="mr-3 rounded-circle"
                  width="50"
                  :src="`assets/img/avatar/avatar-${Math.floor(Math.random() * 5) + 1}.png`"
                  alt="avatar"
                />
                <div class="media-body">
                  <div class="float-right text-primary">{{ $globalFunc.getDate(ticket.created_at) }}</div>
                  <div class="media-title">{{ ticket.title}}</div>
                  <span class="text-small text-muted"
                    >{{ ticket.description.substring(0, 100) }}</span
                  >
                </div>
              </li>
              <div class="text-center pt-1 pb-1" v-if="userType != 'agent'">
                <router-link to="/tickets" class="btn btn-primary btn-lg btn-round">
                  View All
                </router-link>
              </div>
            </ul>
            <ul class="list-unstyled list-unstyled-border" v-else>
              <li class="media">
                <div class="media-body">
                  <p><i>No tickets available</i></p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-12">
        <div class="card">
          <div class="card-header">
            <h4 class="d-inline">Upcoming Tasks List</h4>
          </div>
          <div class="card-body">
            <ul class="list-unstyled list-unstyled-border" v-if="tasks && tasks.checklists && tasks.checklists.length > 0">
              <li class="media" v-for="(task, index) in tasks && tasks.checklists && tasks.checklists.slice(0, 6)" :key="index">
                <img
                  class="mr-3 rounded-circle"
                  width="50"
                  :src="`assets/img/avatar/avatar-${Math.floor(Math.random() * 5) + 1}.png`"
                  alt="avatar"
                />
                <div class="media-body">
                  <div class="badge badge-pill badge-danger mb-1 float-right" v-if="task.status == 'open'">
                    Not Finished
                  </div>
                  <div class="badge badge-pill badge-success mb-1 float-right" v-else>
                    Completed
                  </div>
                  <h6 class="media-title"><router-link :to="`/tasks/view/${task.task_id}`">{{ task.title }}</router-link></h6>
                  <div class="text-small text-muted">
                    Due date: <span class="text-primary">{{ $globalFunc.getDate(task.due_date) }}</span>
                  </div>
                </div>
              </li>
            </ul>
            <ul class="list-unstyled list-unstyled-border" v-else>
              <li class="media">
                <div class="media-body">
                  <p><i>No tasks available</i></p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import GeneralHelpers from "@/functions/actions/GeneralRequest";
export default {
  name: "Dashboard",
  computed: {
    ...mapState(["loading", "userType"]),
    filteredTasks() {
      return this.tasks
    }
  },
  mounted() {
    this.retrieveDashboardStats()
  },
  data() {
    return {
      incoming_calls: {},
      tickets: {},
      tasks: {}
    }
  },
  methods: {
    async retrieveDashboardStats() {
      const response = await GeneralHelpers.getDashboardStats(this.fields);
      if (response.status) {
        this.incoming_calls = response.incoming_calls
        this.tickets = response.tickets 
        this.tasks = response.tasks
      } else {
        this.showAlert(
          "Error occured",
          `${response.message}`,
          "error"
        );
      }
    },
    showAlert(title, text, type) {
      this.$fire({
        title,
        text,
        type: type ? type : "warning",
      });
    },
  },
};
</script>

<style scoped>
</style>